import gql from "graphql-tag";

//作成
export const CREATE_PLAN = gql`
  mutation createPlan($input:PlanInput){
    createPlan(input:$input){
      plan{
        id
        name
      }
    }
  }`;

export const UPDATE_PLAN = gql`
  mutation updatePlan($id:ID,$input:PlanInput){
    updatePlan(id:$id,input:$input){
      plan{
        id
        name
      }
    }
  }`;

export const DELETE_PLAN = gql`
  mutation deletePlan($id:ID){
    deletePlan(id:$id){
      plan{
        id
        name
      }
    }
  }`;
