<template>
  <div>
    <plan-update-modal :propPlan="plan" ref="planUpdateModal" @refetch="planRefetch" />
    <plan-delete-modal ref="planDeleteModal" :planId="planId" @refetch="planRefetch" />
    <b-table responsive :fields="list" :items="this.plans.edges">
      <template v-slot:cell(name)="data">
        <td>{{data.item.node.name}}</td>
      </template>
      <template v-slot:cell(update)="data">
        <b-button
          type="submit"
          size="sm"
          variant="primary"
          class="icon-pencil"
          v-on:click="showUpdateDialog(data.item.node)"
        ></b-button>
      </template>
      <template v-slot:cell(delete)="data">
        <b-button
          type="submit"
          size="sm"
          variant="danger"
          class="icon-trash"
          v-on:click="showDeleteDialog(data.item.node.id)"
        ></b-button>
      </template>
    </b-table>
    <plan-create-form ref="planCreateModal" @refetch="planRefetch" />
  </div>
</template>

<script>
import PlanCreateForm from "./PlanCreateForm";
import PlanUpdateModal from "./PlanUpdateModal";
import PlanDeleteModal from "./PlanDeleteModal";
import { PLAN_QUERY } from "../../components/job-query";

export default {
  components: {
    PlanUpdateModal,
    PlanDeleteModal,
    PlanCreateForm
  },
  data() {
    return {
      list: [
        { key: "name", label: "名前" },
        { key: "update", label: "編集" },
        { key: "delete", label: "削除" }
      ],
      plan: null,
      plans: {},
      planId: null,
      visible: false
    };
  },
  apollo: {
    plans: {
      query: PLAN_QUERY,
      result({ data, loading }) {},
      error(error) {
        console.error("plan", error);
      }
    }
  },
  methods: {
    // --------------------------------
    // modalを表示
    // --------------------------------
    showCreateDialog: function() {
      this.$refs.planCreateModal.showDialog();
    },
    // --------------------------------
    // modalを表示
    // --------------------------------
    showUpdateDialog: function(plan) {
      this.plan = plan;
      this.$refs.planUpdateModal.showDialog();
    },
    // --------------------------------
    // modalを表示
    // --------------------------------
    showDeleteDialog: function(id) {
      this.planId = id;
      this.$refs.planDeleteModal.showDialog();
    },
    // --------------------------------
    // 畑情報の再取得
    // --------------------------------
    planRefetch: function() {
      this.$apollo.queries.plans.refetch();
    }
  }
};
</script>
