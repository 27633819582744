<template>
  <div>
    <b-modal ref="dialog" @hidden="close" hide-footer title="変更">
      <b-row>
        <b-col>
          <b-form-group label="作業内容名">
            <b-form-input type="text" v-model="plan.name"></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col class="text-right">
          <b-button type="submit" size="sm" variant="primary" v-on:click="updatePlan">
            <i class="fa fa-dot-circle-o"></i> 更新
          </b-button>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import { UPDATE_PLAN } from "../../components/plan-mutation";

export default {
  components: {},
  data() {
    return {
      planId: null,
      plan: { name: null }
    };
  },
  props: {
    propPlan: {
      type: Object,
      require: false,
      default: () => ({})
    }
  },
  watch: {
    propPlan: function() {
      this.init();
    }
  },
  methods: {
    init: function() {
      this.planId = this.propPlan.id;
      this.plan.name = this.propPlan.name;
    },
    close: function() {
      this.init();
    },
    // --------------------------------
    // modalを開く
    // --------------------------------
    showDialog: function() {
      this.$refs["dialog"].show();
    },
    // --------------------------------
    // 更新
    // --------------------------------
    updatePlan: function() {
      this.$apollo
        .mutate({
          mutation: UPDATE_PLAN,
          variables: {
            id: this.planId,
            input: this.plan
          }
        })
        .then(data => {
          this.$refs["dialog"].hide();
        })
        .catch(error => {
          alert(error);
        });
    }
  }
};
</script>
