<template>
  <div>
    <b-card>
      <b-row>
        <b-col>
          <h3>ロボット作業マスタ 編集</h3>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <plan-list />
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import PlanList from "../components/PlanList";

const DEFAULT_INDEX = 0;

export default {
  components: {
    PlanList
  },
  data() {
    return {};
  }
};
</script>
